import styled from "styled-components";
import Select from "react-select";
import { darkBlue, white } from "../colors";
import { deviceMF } from "../breakPoints";

export const HeaderContainer = styled.div`
  width: 100%;
  height: 70px;
  background-color: ${white};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1.2rem;
  box-shadow: 0 10px 14px rgba(0, 0, 0, 0.2);

  @media ${deviceMF.tablet} {
    padding: 0 3.6rem;
  }
`;
export const NavStyled = styled.nav`
  display: flex;
  align-items: center;
`;
export const LogoImage = styled.img`
  margin-top: 2rem;
`;
export const NavContainer = styled.ul`
  list-style: none;
  justify-content: space-between;
  display: none;
  @media ${deviceMF.laptop} {
    display: flex;
  }
`;
export const NavItem = styled.li`
  color: ${darkBlue};
  font-size: 18px;
  font-weight: 500;
  margin: 0 0.6rem;
  cursor: pointer;
  transition: all ease-out 0.25s;
  &:active {
    transform: scale(0.9);
  }
`;
export const SelectStyled = styled(Select)`
  width: 220px;
  z-index: 3;
`;
export const SelectOption = styled.div`
  display: flex;
  align-items: center;
`;
export const IconSelect = styled.img`
  margin-right: 10px;
`;
