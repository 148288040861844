import React from "react";
import { CustomersContainer } from "../../assets/styles/views/customers";
import CustomerItem from "../../components/CustomerItem";
import Title from "../../components/Title";
import { useGlobalContext } from "../../providers/GlobalProvider";

const Customers = () => {
  const { selectedLenguage } = useGlobalContext();
  const { clients } = selectedLenguage;
  return (
    <>
      <Title text={clients.title} />
      <CustomersContainer>
        <CustomerItem />
        <CustomerItem />
        <CustomerItem />
        <CustomerItem />
        <CustomerItem />
        <CustomerItem />
        <CustomerItem />
        <CustomerItem />
        <CustomerItem />
        <CustomerItem />
        <CustomerItem />
        <CustomerItem />
        <CustomerItem />
        <CustomerItem />
        <CustomerItem />
        <CustomerItem />
        <CustomerItem />
        <CustomerItem />
        <CustomerItem />
        <CustomerItem />
        <CustomerItem />
        <CustomerItem />
        <CustomerItem />
        <CustomerItem />
      </CustomersContainer>
    </>
  );
};

export default Customers;
