import styled from "styled-components";
import { brightBlue, darkGrey, mediumGrey, softGrey } from "../colors";
import { deviceMF } from "../breakPoints";

export const SearchContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
  @media ${deviceMF.mobileL} {
    margin-bottom: 4rem;
  }
  @media ${deviceMF.tablet} {
    margin-bottom: 6rem;
  }
`;

export const SearchInput = styled.input`
  width: 280px;
  color: ${darkGrey};
  height: 40px;
  border-radius: 8px;
  border: 1px solid ${softGrey};
  padding: 0.6rem;
  &:focus {
    outline: none;
    border: 2px solid ${brightBlue};
  }
  &::placeholder {
    color: ${mediumGrey};
  }
  @media ${deviceMF.mobileL} {
    width: 380px;
  }
`;
