import styled from "styled-components";
import { deviceMF } from "../breakPoints";

export const CustomersContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 1rem 1.2rem 3rem;
  @media ${deviceMF.mobileL} {
    padding: 0 1.8rem 4rem;
  }
  @media ${deviceMF.tablet} {
    padding: 0 4rem 6rem;
  }
`;
