import React from "react";
import { HashRouter, Switch, Route } from "react-router-dom";
import { GlobalStyle } from "../../assets/styles/GlobalStyles";
import Popup from "reactjs-popup";

// views
import Contact from "../../views/Contact";
import Customers from "../../views/Customers";
import Home from "../../views/Home";
import Proyects from "../../views/Proyects";
import Services from "../../views/Services";

// components
import { Layout } from "../../components/Layout";
import ScrollToTop from "../../components/ScrollToTop";
import BurgerIcon from "../../components/BurgerIcon";
import BurgerMenu from "../../components/BurgerMenu";

const contentStyle = {
  background: "rgba(34,34,34,0)",
  width: "80%",
  border: "none",
};

const App = () => {
  return (
    <>
      <GlobalStyle />
      <HashRouter>
        <ScrollToTop>
          <Switch>
            <Layout>
              <Route exact path="/contacto" component={Contact} />
              <Route exact path="/clientes" component={Customers} />
              <Route exact path="/" component={Home} />
              <Route exact path="/proyectos" component={Proyects} />
              <Route exact path="/servicios" component={Services} />
            </Layout>
            <Route component={Home} />
          </Switch>
        </ScrollToTop>
        <Popup
          modal
          overlayStyle={{ background: "rgba(34,34,34,0.98" }}
          contentStyle={contentStyle}
          closeOnDocumentClick={false}
          trigger={(open) => <BurgerIcon open={open} />}
        >
          {(close) => <BurgerMenu close={close} />}
        </Popup>
      </HashRouter>
    </>
  );
};

export default App;
