import styled from "styled-components";
import { fadeIn } from "../animation";
import { grey } from "../colors";
import { deviceMF } from "../breakPoints";

export const PageContainer = styled.div`
  padding: 0 1.2rem 3rem;
  @media ${deviceMF.mobileL} {
    padding: 0 1.8rem 4rem;
  }
  @media ${deviceMF.tablet} {
    padding: 0 4rem 6rem;
  }
`;
export const ArticleContainer = styled.article`
  display: flex;
  margin-bottom: 2rem;
  align-items: center;
  flex-direction: column;
  @media ${deviceMF.tablet} {
    flex-direction: row;
  }
`;
export const ArticleSlider = styled.div`
  width: 300px;
  align-self: center;
  height: auto;
  margin: 0 0 1.6rem 0;
  @media ${deviceMF.mobileL} {
    width: 500px;
    margin: 0 0 1.6rem 0;
  }
  @media ${deviceMF.tablet} {
    margin: 0 2rem 1.6rem 0;
    align-self: flex-start;
  }
  @media ${deviceMF.laptop} {
    width: 600px;
  }
`;
export const InfoArticleContainer = styled.div`
  flex: 1 1 auto;
`;
export const ServiceImage = styled.img`
  ${fadeIn()}
  width: 300px;
  height: auto;
  border-radius: 1.2rem;
  @media ${deviceMF.mobileL} {
    width: 500px;
  }
  @media ${deviceMF.laptop} {
    width: 600px;
  }
`;
export const ArticleTitle = styled.h5`
  font-size: 18px;
  margin: 0 0 0.4rem;
  @media ${deviceMF.mobileL} {
    font-size: 20px;
  }
`;
export const ArticleText = styled.p`
  text-align: justify;
  color: ${grey};
  margin-bottom: 1rem;
  font-size: 14px;
  @media ${deviceMF.mobileL} {
    font-size: 16px;
  }
`;
export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;
