import React from "react";
import {
  FooterStyled,
  FooterText,
} from "../../assets/styles/components/footer";

const Footer = () => {
  return (
    <FooterStyled>
      <FooterText>
        Azviu Electric 2021 | Develop by{" "}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://vainilladev.com/"
        >
          Vainilla Dev
        </a>
      </FooterText>
    </FooterStyled>
  );
};

export default Footer;
