import React, { useState } from "react";
import Title from "../../components/Title";
import Button from "../../components/Button";
import {
  ButtonContainer,
  ContactInfoWrapper,
  ContactInput,
  ContactText,
  ContactWrapper,
  FormStyled,
  FormTitle,
  InfoText,
  InfoTitlte,
  LabelForm,
} from "../../assets/styles/views/contact";
import { useGlobalContext } from "../../providers/GlobalProvider";

const Contact = () => {
  const { selectedLenguage } = useGlobalContext();
  const { contact } = selectedLenguage;
  const [form, setForm] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });

  const handleChangeForm = (event) => {
    const { value, name } = event.target;
    setForm((prevState) => ({ ...prevState, [name]: value }));
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(form);
  };
  return (
    <>
      <Title text={contact.title} />
      <ContactWrapper>
        <FormStyled onSubmit={handleSubmit}>
          <FormTitle>{contact.form.title}</FormTitle>
          <LabelForm htmlFor="name">{contact.form.field1.name}</LabelForm>
          <ContactInput
            onChange={handleChangeForm}
            name="name"
            type="text"
            placeholder={contact.form.field1.placeholder}
          />
          <LabelForm htmlFor="email">{contact.form.field2.name}</LabelForm>
          <ContactInput
            onChange={handleChangeForm}
            name="email"
            type="text"
            placeholder={contact.form.field2.placeholder}
          />
          <LabelForm htmlFor="phone">{contact.form.field3.name}</LabelForm>
          <ContactInput
            onChange={handleChangeForm}
            name="phone"
            type="text"
            placeholder={contact.form.field3.placeholder}
          />
          <LabelForm htmlFor="message">{contact.form.field4.name}</LabelForm>
          <ContactText
            onChange={handleChangeForm}
            name="message"
            type="text"
            placeholder={contact.form.field4.placeholder}
          />
          <ButtonContainer>
            <Button type="submit" color="primary">
              {contact.form.button}
            </Button>
          </ButtonContainer>
        </FormStyled>
        <ContactInfoWrapper>
          <InfoTitlte>{contact.information.addressTitle}</InfoTitlte>
          <InfoText>{contact.information.address}</InfoText>
          <InfoTitlte>{contact.information.telephoneTitle}</InfoTitlte>
          <InfoText>{contact.information.telephone}</InfoText>
          <InfoTitlte>{contact.information.emailTitle}</InfoTitlte>
          <InfoText>{contact.information.email}</InfoText>
          <Button color="primary">
            {contact.information.sendWhatsAppButton}
          </Button>
          <Button color="primary">
            {contact.information.curriculumButton}
          </Button>
        </ContactInfoWrapper>
      </ContactWrapper>
    </>
  );
};

export default Contact;
