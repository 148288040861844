import styled from "styled-components";
import { darkBlue, white } from "../colors";
import { deviceMF } from "../breakPoints";

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 3rem 0;
`;
export const H1Styled = styled.h1`
  color: ${(props) => (props.isWhite ? white : darkBlue)};
  font-size: 32px;
  font-weight: bolder;
  text-align: center;
  margin-bottom: 0.6rem;
  @media ${deviceMF.tablet} {
    font-size: 48px;
  }
`;

export const Bar = styled.div`
  height: 10px;
  width: 200px;
  background-color: ${(props) => (props.isWhite ? white : darkBlue)};
  position: relative;
  overflow: hidden;
  &::before {
    content: "";
    background-color: ${(props) => (props.isWhite ? darkBlue : white)};
    width: 50px;
    height: 10px;
    position: absolute;
    left: -27px;
    transform: rotate(45deg);
  }
  &::after {
    content: "";
    background-color: ${(props) => (props.isWhite ? darkBlue : white)};
    width: 50px;
    height: 10px;
    position: absolute;
    left: 177px;
    transform: rotate(45deg);
  }
`;
