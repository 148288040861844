import { lenguages } from "../helpers/lenguage";

// actions
export const types = {
  CHANGE_LENGUAGE: "CHANGE_LENGUAGE",
};
// initial state
export const initialState = {
  selectedLenguage: lenguages.spanish,
  value: {
    value: "spanish",
    label: "Español",
  },
  lenguages: [
    {
      value: "spanish",
      label: "Español",
    },
    {
      value: "english",
      label: "English",
    },
  ],
  texts: lenguages,
};
// actions creators
export const changeLenguage = (newLenguage) => {
  return {
    type: types.CHANGE_LENGUAGE,
    payload: {
      newLenguage,
    },
  };
};

// reducer
export const reducer = (state, action) => {
  switch (action.type) {
    case types.CHANGE_LENGUAGE:
      const { newLenguage } = action.payload;
      return {
        ...state,
        selectedLenguage: lenguages[newLenguage.value],
        value: newLenguage,
      };

    default:
      return state;
  }
};
