export const lenguages = {
  spanish: {
    navbar: {
      us: "Nosotros",
      services: "Servicios",
      customers: "Clientes",
      projects: "Proyectos",
      contact: "Contacto",
    },
    us: {
      title: "NOSOTROS",
      aboutInfo:
        "Azviu Electric S. de R.L. de C.V. Es una constructora de Obra Eléctrica de Media y Baja Tensión, con 20 años de experiencia en el sector siempre teniendo como objetivo el satisfacer las necesidades del cliente.",
      valuesSection: {
        mision: {
          title: "Misión",
          text: "Ser una empresa comprometida en el campo laboral de obra eléctrica, brindando el mejor servicio, calidad y eficiencia en nuestros procesos.",
        },
        vision: {
          title: "Visión",
          text: "Consolidarnos como empresa líder en el mercado, brindando siempre innovación y rentabilidad de nuestros proyectos.",
        },
        politics: {
          title: "Politicas",
          text: "Azviu Electric busca superar siempre las expectativas del cliente cumpliendo las necesidad o requerimientos en forma oportuna y efectiva, tanto en diseño, construcción y mantenimiento, mediante procesos eficaces, siempre innovando y apoyándonos con un equipo altamente capacitado.",
        },
      },
      curriculumSection: {
        title: "CURRíCULUM",
        button: "VER CURRíCULUM",
      },
    },
    services: {
      title: "SERVICIOS",
      articleTitle: "Descripción",
      services: {
        designSection: {
          title: "Diseño",
          description:
            "Nuestros diseños son elaborados por un equipo de ingenieros capacitados y con experiencia en la realización de instalaciones eléctricas de media y baja tensión.",
        },
        constructionSection: {
          title: "Construcción",
          description:
            "Ejecución de obras en sectores industriales, comerciales, turísticos, habitacionales y de usos múltiples ya sea eléctrico, hidrosanitario, sistemas especiales y HVAC.",
        },
        managmentSection: {
          title: "Gestoría",
          description:
            "Realizamos todo el trámite administrativo ante CFE para contrataciones de energía o aclaraciones.",
        },
        supervisionSection: {
          title: "Supervisión",
          description:
            "Monitoreamos y controlamos cada etapa del proceso de construcción, enfocándonos en el cumplimiento de las normas vigentes y necesidades del cliente.",
        },
        maintainmentSection: {
          title: "Mantenimiento",
          description:
            "Ofrecemos servicios de mantenimiento con la mas alta calidad y seguridad para el cliente. Monitoreando el estado de los equipos y las condiciones de trabajo, para así prolongar la vida útil de la instalación.",
        },
      },
      button: "Ver Currículum",
    },
    clients: {
      title: "CLIENTES",
    },
    projects: {
      title: "PROYECTOS",
      placeholder: "Buscar...",
      descriptionTitle: "Descripción",
      typeTitle: "Tipo",
      dateTitle: "Fecha",
      projects: [
        {
          title: "LEBEN PROVIDENCIA",
          description: "Obra Eléctrica.",
          type: "Proyecto",
          date: "",
        },
        {
          title: "NOVENA MEXICO",
          description: "Obra Eléctrica.",
          type: "Proyecto",
          date: "",
        },
        {
          title: "DARIO BUSINESS CENTER",
          description: "Obra Eléctrica.",
          type: "Proyecto",
          date: "",
        },
        {
          title: "TREX LA VENTA",
          description: "Obra Eléctrica.",
          type: "Proyecto",
          date: "",
        },
        {
          title: "SAN ACACIO",
          description: "Obra Eléctrica.",
          type: "Proyecto",
          date: "",
        },
        {
          title: "PLAZA SENDEROS",
          description: "Obra Eléctrica.",
          type: "Proyecto",
          date: "",
        },
        {
          title: "PLAZA INDEPENDENCIA",
          description: "Obra Eléctrica.",
          type: "Proyecto",
          date: "",
        },
        {
          title: "TORRE BOLONIA",
          description: "Obra Eléctrica.",
          type: "Proyecto",
          date: "",
        },
        {
          title: "COUNTRY HABITAT",
          description: "Obra Eléctrica.",
          type: "Proyecto",
          date: "",
        },
        {
          title: "TORRE 4 VIENTOS",
          description: "Obra Eléctrica.",
          type: "Proyecto",
          date: "",
        },
        {
          title: "TEQUILERA 3 AGAVES",
          description: "Obra Eléctrica.",
          type: "Proyecto",
          date: "",
        },
        {
          title: "NOVENA MEXICO",
          description:
            "Diseño de Instalaciones. Uso comercial tanto oficinas como locales.",
          type: "Diseño",
          date: "",
        },
        {
          title: "BRAVANTE",
          description:
            "Diseño de Instalaciones. Uso mixto tanto de oficinas comerciales como habitacional.",
          type: "Diseño",
          date: "",
        },
        {
          title: "VALENTINA",
          description: "Diseño de Instalaciones. Uso habitacional.",
          type: "Diseño",
          date: "",
        },
        {
          title: "NEXTIPARK",
          description: "Diseño de Instalaciones. Parque industrial.",
          type: "Diseño",
          date: "",
        },
        {
          title: "TORRE BUCE",
          description:
            "Diseño de Instalaciones. Uso mixto como zona habitacional y oficinas comerciales",
          type: "Diseño",
          date: "",
        },
        {
          title: "TEQUILERA 3 AGAVES",
          description: "Diseño de Instalaciones. Uso industrial.",
          type: "Diseño",
          date: "",
        },
      ],
      button: "Ver Currículum",
    },
    contact: {
      title: "CONTACTO",
      form: {
        title: "Envíanos un mensaje",
        field1: {
          name: "Nombre",
          placeholder: "Nombre",
        },
        field2: {
          name: "Correo",
          placeholder: "Correo",
        },
        field3: {
          name: "Teléfono",
          placeholder: "Teléfono",
        },
        field4: {
          name: "Mensaje",
          placeholder: "Mensaje",
        },
        button: "Enviar",
      },
      information: {
        addressTitle: "Dirección",
        telephoneTitle: "Telefono",
        emailTitle: "Correo",
        address: "",
        telephone: "",
        email: "",
        sendWhatsAppButton: "Enviar WhatsApp",
        curriculumButton: "VER CURRÍCULUM",
      },
    },
  },
  english: {
    navbar: {
      us: "Us",
      services: "Services",
      customers: "Customers",
      projects: "Projects",
      contact: "Contact",
    },
    us: {
      title: "US",
      aboutInfo:
        "Azviu Electric S. de R.L. de C.V. It is a construction company of Medium and Low Voltage Electrical Works, with 20 years of experience in the sector, always aiming to satisfy the client's needs.",
      valuesSection: {
        mision: {
          title: "Mision",
          text: "To be a company committed to the labor field of electrical work, providing the best service, quality and efficiency in our processes.",
        },
        vision: {
          title: "Vision",
          text: "Consolidate ourselves as a leading company in the market, always providing innovation and profitability of our projects.",
        },
        politics: {
          title: "Policies",
          text: "Azviu Electricalways seeks to exceed customer expectations by meeting the needs or requirements in a timely and effective manner, both in design, construction and maintenance, through efficient processes, always innovating and supporting us with a highly trained team.",
        },
      },
      curriculumSection: {
        title: "CURRICULUM",
        button: "SEE CURRICULUM",
      },
    },
    services: {
      title: "SERVICES",
      articleTitle: "Description",
      services: {
        designSection: {
          title: "Design",
          description:
            "Our designs are made by a team of trained engineers with experience in carrying out medium and low voltage electrical installations.",
        },
        constructionSection: {
          title: "Construction",
          description:
            "Execution of works in industrial, commercial, tourist, housing and multipurpose sectors, be it electrical, plumbing, special systems and HVAC.",
        },
        managmentSection: {
          title: "Managment",
          description:
            "We carry out all the administrative procedures before CFE for energy contracts or clarifications.",
        },
        supervisionSection: {
          title: "Supervision",
          description:
            "We monitor and control each stage of the construction process, focusing on compliance with current regulations and customer needs.",
        },
        maintainmentSection: {
          title: "Maintainment",
          description:
            "We offer maintenance services with the highest quality and security for the client. Monitoring the status of the equipment and working conditions, in order to extend the useful life of the installation.",
        },
      },
      button: "See Curriculum",
    },
    clients: {
      title: "CUSTOMERS",
    },
    projects: {
      title: "PROJECTS",
      placeholder: "Search...",
      descriptionTitle: "Description",
      typeTitle: "Type",
      dateTitle: "Date",
      projects: [
        {
          title: "LEBEN PROVIDENCIA",
          description: "Electrical Work.",
          type: "Project",
          date: "",
        },
        {
          title: "NOVENA MEXICO",
          description: "Electrical Work.",
          type: "Project",
          date: "",
        },
        {
          title: "DARIO BUSINESS CENTER",
          description: "Electrical Work.",
          type: "Project",
          date: "",
        },
        {
          title: "TREX LA VENTA",
          description: "Electrical Work.",
          type: "Project",
          date: "",
        },
        {
          title: "SAN ACACIO",
          description: "Electrical Work.",
          type: "Project",
          date: "",
        },
        {
          title: "PLAZA SENDEROS",
          description: "Electrical Work.",
          type: "Project",
          date: "",
        },
        {
          title: "PLAZA INDEPENDENCIA",
          description: "Electrical Work.",
          type: "Project",
          date: "",
        },
        {
          title: "TORRE BOLONIA",
          description: "Electrical Work.",
          type: "Project",
          date: "",
        },
        {
          title: "COUNTRY HABITAT",
          description: "Electrical Work.",
          type: "Project",
          date: "",
        },
        {
          title: "TORRE 4 VIENTOS",
          description: "Electrical Work.",
          type: "Project",
          date: "",
        },
        {
          title: "TEQUILERA 3 AGAVES",
          description: "Electrical Work.",
          type: "Project",
          date: "",
        },
        {
          title: "NOVENA MEXICO",
          description:
            "Facilities Design. Commercial use both offices and premises.",
          type: "Design",
          date: "",
        },
        {
          title: "BRAVANTE",
          description:
            "Facilities Design. Mixed use of both commercial and residential offices.",
          type: "Design",
          date: "",
        },
        {
          title: "VALENTINA",
          description: "Facilities Design. Housing use.",
          type: "Design",
          date: "",
        },
        {
          title: "NEXTIPARK",
          description: "Facilities Design. Industrial park.",
          type: "Design",
          date: "",
        },
        {
          title: "TORRE BUCE",
          description:
            "Facilities Design. Mixed use as a residential area and commercial offices.",
          type: "Design",
          date: "",
        },
        {
          title: "TEQUILERA 3 AGAVES",
          description: "Facilities Design. Industrial use.",
          type: "Design",
          date: "",
        },
      ],
      button: "See Curriculum",
    },
    contact: {
      title: "CONTACT",
      form: {
        title: "Send us a message",
        field1: {
          name: "Name",
          placeholder: "Name",
        },
        field2: {
          name: "Email",
          placeholder: "Email",
        },
        field3: {
          name: "Telephone",
          placeholder: "Telephone",
        },
        field4: {
          name: "Message",
          placeholder: "Message",
        },
        button: "Send",
      },
      information: {
        addressTitle: "Address",
        telephoneTitle: "Phone",
        emailTitle: "Email",
        address: "",
        telephone: "",
        email: "",
        sendWhatsAppButton: "Send WhatsApp",
        curriculumButton: "SEE CURRICULUM",
      },
    },
  },
};
