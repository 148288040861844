import React, { useRef } from "react";
import SwiperCore, { Autoplay, Navigation, Pagination, A11y } from "swiper";

import { Swiper, SwiperSlide } from "swiper/react";
import {
  ArticleContainer,
  ArticleSlider,
  ArticleText,
  ArticleTitle,
  ButtonContainer,
  PageContainer,
  ServiceImage,
} from "../../assets/styles/views/services";
import Subtitle from "../../components/Subtitle";
import Title from "../../components/Title";
import Button from "../../components/Button";

import ServiceImg from "../../assets/images/service.jpg";

// Import Swiper styles
import "swiper/swiper-bundle.css";
import { useGlobalContext } from "../../providers/GlobalProvider";
// import "../styles/swipper.css";

// install Swiper modules
SwiperCore.use([Autoplay, Navigation, Pagination, A11y]);

const Services = () => {
  const swiperRef = useRef(null);
  const { selectedLenguage } = useGlobalContext();
  const { services } = selectedLenguage;

  return (
    <>
      <Title text={services.title} />
      <PageContainer>
        <Subtitle>{services.services.designSection.title}</Subtitle>
        <ArticleContainer>
          <ArticleSlider>
            <Swiper
              ref={swiperRef}
              slidesPerView={1}
              freeMode={true}
              navigation
              autoHeight
              loop
              centeredSlides
              spaceBetween={30}
              className="mySwiper"
            >
              <SwiperSlide>
                <ServiceImage src={ServiceImg} alt="Producto" />
              </SwiperSlide>
              <SwiperSlide>
                <ServiceImage src={ServiceImg} alt="Producto" />
              </SwiperSlide>
              <SwiperSlide>
                <ServiceImage src={ServiceImg} alt="Producto" />
              </SwiperSlide>
            </Swiper>
          </ArticleSlider>
          <div>
            <ArticleTitle>{services.articleTitle}:</ArticleTitle>
            <ArticleText>
              {services.services.designSection.description}
            </ArticleText>
          </div>
        </ArticleContainer>
        <Subtitle>{services.services.constructionSection.title}</Subtitle>
        <ArticleContainer>
          <ArticleSlider>
            <Swiper
              ref={swiperRef}
              slidesPerView={1}
              freeMode={true}
              navigation
              autoHeight
              loop
              centeredSlides
              spaceBetween={30}
              className="mySwiper"
            >
              <SwiperSlide>
                <ServiceImage src={ServiceImg} alt="Producto" />
              </SwiperSlide>
              <SwiperSlide>
                <ServiceImage src={ServiceImg} alt="Producto" />
              </SwiperSlide>
              <SwiperSlide>
                <ServiceImage src={ServiceImg} alt="Producto" />
              </SwiperSlide>
            </Swiper>
          </ArticleSlider>
          <div>
            <ArticleTitle>{services.articleTitle}:</ArticleTitle>
            <ArticleText>
              {services.services.constructionSection.description}
            </ArticleText>
          </div>
        </ArticleContainer>
        <Subtitle>{services.services.managmentSection.title}</Subtitle>
        <ArticleContainer>
          <ArticleSlider>
            <Swiper
              ref={swiperRef}
              slidesPerView={1}
              freeMode={true}
              navigation
              autoHeight
              loop
              centeredSlides
              spaceBetween={30}
              className="mySwiper"
            >
              <SwiperSlide>
                <ServiceImage src={ServiceImg} alt="Producto" />
              </SwiperSlide>
              <SwiperSlide>
                <ServiceImage src={ServiceImg} alt="Producto" />
              </SwiperSlide>
              <SwiperSlide>
                <ServiceImage src={ServiceImg} alt="Producto" />
              </SwiperSlide>
            </Swiper>
          </ArticleSlider>
          <div>
            <ArticleTitle>{services.articleTitle}:</ArticleTitle>
            <ArticleText>
              {services.services.managmentSection.description}
            </ArticleText>
          </div>
        </ArticleContainer>
        <Subtitle>{services.services.supervisionSection.title}</Subtitle>
        <ArticleContainer>
          <ArticleSlider>
            <Swiper
              ref={swiperRef}
              slidesPerView={1}
              freeMode={true}
              navigation
              autoHeight
              loop
              centeredSlides
              spaceBetween={30}
              className="mySwiper"
            >
              <SwiperSlide>
                <ServiceImage src={ServiceImg} alt="Producto" />
              </SwiperSlide>
              <SwiperSlide>
                <ServiceImage src={ServiceImg} alt="Producto" />
              </SwiperSlide>
              <SwiperSlide>
                <ServiceImage src={ServiceImg} alt="Producto" />
              </SwiperSlide>
            </Swiper>
          </ArticleSlider>
          <div>
            <ArticleTitle>{services.articleTitle}:</ArticleTitle>
            <ArticleText>
              {services.services.supervisionSection.description}
            </ArticleText>
          </div>
        </ArticleContainer>
        <Subtitle>{services.services.maintainmentSection.title}</Subtitle>
        <ArticleContainer>
          <ArticleSlider>
            <Swiper
              ref={swiperRef}
              slidesPerView={1}
              freeMode={true}
              navigation
              autoHeight
              loop
              centeredSlides
              spaceBetween={30}
              className="mySwiper"
            >
              <SwiperSlide>
                <ServiceImage src={ServiceImg} alt="Producto" />
              </SwiperSlide>
              <SwiperSlide>
                <ServiceImage src={ServiceImg} alt="Producto" />
              </SwiperSlide>
              <SwiperSlide>
                <ServiceImage src={ServiceImg} alt="Producto" />
              </SwiperSlide>
            </Swiper>
          </ArticleSlider>
          <div>
            <ArticleTitle>{services.articleTitle}:</ArticleTitle>
            <ArticleText>
              {services.services.maintainmentSection.description}
            </ArticleText>
          </div>
        </ArticleContainer>
        <ButtonContainer>
          <Button color="primary">{services.button}</Button>
        </ButtonContainer>
      </PageContainer>
    </>
  );
};

export default Services;
