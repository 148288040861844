import React from "react";
import SwiperCore, { Autoplay, Navigation, Pagination, A11y } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import {
  AboutInfoContainer,
  AboutText,
  HeroContainer,
  InfoSection,
  Value,
  ValueCircle,
  ValuesSection,
  ValueText,
  ValueTitle,
} from "../../assets/styles/views/home";

// images
import Background from "../../assets/images/slider.png";
import MisionIcon from "../../assets/images/icons/mision.png";
import TelescopeIcon from "../../assets/images/icons/telescope.png";
import ValorIcon from "../../assets/images/icons/valor.png";
import "swiper/swiper-bundle.css";
import Title from "../../components/Title";
import Button from "../../components/Button";
import { useGlobalContext } from "../../providers/GlobalProvider";

// install Swiper modules
SwiperCore.use([Autoplay, Navigation, Pagination, A11y]);

const Home = () => {
  const { selectedLenguage } = useGlobalContext();
  const { us } = selectedLenguage;
  return (
    <>
      <Swiper
        spaceBetween={0}
        slidesPerView={1}
        navigation
        autoHeight
        loop
        pagination={{ clickable: true }}
        autoplay={{
          delay: 24000,
          disableOnInteraction: false,
        }}
      >
        <SwiperSlide>
          <HeroContainer background={Background}></HeroContainer>
        </SwiperSlide>
        <SwiperSlide>
          <HeroContainer background={Background}></HeroContainer>
        </SwiperSlide>
        <SwiperSlide>
          <HeroContainer background={Background}></HeroContainer>
        </SwiperSlide>
      </Swiper>
      <Title text={us.title} />
      <AboutInfoContainer>
        <AboutText>{us.aboutInfo}</AboutText>
      </AboutInfoContainer>
      <ValuesSection>
        <Value>
          <ValueCircle>
            <img src={MisionIcon} width={100} alt="Icono de mision" />
            <ValueTitle>{us.valuesSection.mision.title}</ValueTitle>
          </ValueCircle>
          <ValueText>{us.valuesSection.mision.text}</ValueText>
        </Value>
        <Value>
          <ValueCircle>
            <img src={TelescopeIcon} width={100} alt="Icono de vision" />
            <ValueTitle>{us.valuesSection.vision.title}</ValueTitle>
          </ValueCircle>
          <ValueText>{us.valuesSection.vision.text}</ValueText>
        </Value>
        <Value>
          <ValueCircle>
            <img src={ValorIcon} width={100} alt="Icono de valores" />
            <ValueTitle>{us.valuesSection.politics.title}</ValueTitle>
          </ValueCircle>
          <ValueText>{us.valuesSection.politics.text}</ValueText>
        </Value>
      </ValuesSection>
      <InfoSection>
        <Title text={us.curriculumSection.title} isWhite />
        <Button>{us.curriculumSection.button}</Button>
      </InfoSection>
    </>
  );
};

export default Home;
