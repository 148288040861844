import styled from "styled-components";
import { darkBlue, white } from "../colors";
import { deviceMF } from "../breakPoints";

export const ButtonStyled = styled.button`
  font-family: Sans-Serif;
  min-width: 200px;
  height: 40px;
  background-color: ${(props) =>
    props.color !== "primary" ? white : darkBlue};
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  box-shadow: 0 10px 14px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  font-weight: bold;
  font-size: 16px;
  color: ${(props) => (props.color !== "primary" ? darkBlue : white)};
  margin: 10px;
  cursor: pointer;
  @media ${deviceMF.mobileL} {
    height: 50px;
    min-width: 220px;
    font-size: 18px;
  }
`;
