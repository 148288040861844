import styled from "styled-components";
import { darkBlue, white } from "../colors";
import { deviceMF } from "../breakPoints";

export const TitleContainer = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  padding: 1rem 0;
`;
export const H2Styled = styled.h2`
  color: ${(props) => (props.isWhite ? white : darkBlue)};
  font-weight: bolder;
  text-align: start;
  font-size: 22px;
  margin-bottom: 0.4rem;
  @media ${deviceMF.mobileL} {
    margin-bottom: 0.6rem;
    font-size: 28px;
  }
`;

export const Bar = styled.div`
  height: 3px;
  background-color: ${(props) => (props.isWhite ? white : darkBlue)};
  position: relative;
  overflow: hidden;
  width: 100px;

  &::before {
    content: "";
    background-color: ${(props) => (props.isWhite ? darkBlue : white)};
    width: 50px;
    height: 10px;
    position: absolute;
    left: -27px;
    transform: rotate(45deg);
  }
  &::after {
    content: "";
    background-color: ${(props) => (props.isWhite ? darkBlue : white)};
    width: 50px;
    height: 10px;
    position: absolute;
    left: 77px;
    transform: rotate(45deg);
  }
  @media ${deviceMF.mobileL} {
    height: 5px;
    width: 150px;
    &::after {
      content: "";
      background-color: ${(props) => (props.isWhite ? darkBlue : white)};
      width: 50px;
      height: 10px;
      position: absolute;
      left: 127px;
      transform: rotate(45deg);
    }
  }
`;
