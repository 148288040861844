import React from "react";
import {
  Bar,
  H1Styled,
  TitleContainer,
} from "../../assets/styles/components/title";

const Title = ({ text = "", isWhite = false }) => {
  return (
    <TitleContainer>
      <H1Styled isWhite={isWhite}>{text}</H1Styled>
      <Bar isWhite={isWhite}></Bar>
    </TitleContainer>
  );
};

export default Title;
