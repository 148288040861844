import styled from "styled-components";
import { softGrey } from "../colors";
import { deviceMF } from "../breakPoints";

export const CustomerImageContainer = styled.div`
  width: 250px;
  height: 150px;
  border: 2px solid ${softGrey};
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0.8rem 0.6rem;
  border-radius: 0.5rem;

  @media ${deviceMF.tablet} {
    margin: 1.2rem 0.8rem;
  }
`;
