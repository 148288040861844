import React from "react";
import { useHistory } from "react-router-dom";
import BurgerIcon from "../BurgerIcon";
import {
  MenuContainer,
  MenuList,
  MenuListItem,
  CloseContainer,
} from "../../assets/styles/components/burgerMenu";

const BurgerMenu = ({ close }) => {
  let history = useHistory();

  const handleRedirect = (path) => {
    history.push(path);
    close();
  };

  return (
    <>
      <CloseContainer>
        <BurgerIcon onClick={close} open />
      </CloseContainer>
      <MenuContainer>
        <MenuList>
          <MenuListItem onClick={() => handleRedirect("/")}>
            Nosotros
          </MenuListItem>
          <MenuListItem onClick={() => handleRedirect("/servicios")}>
            Servicios
          </MenuListItem>
          <MenuListItem onClick={() => handleRedirect("/clientes")}>
            Clientes
          </MenuListItem>
          <MenuListItem onClick={() => handleRedirect("/proyectos")}>
            Proyectos
          </MenuListItem>
          <MenuListItem onClick={() => handleRedirect("/contacto")}>
            Contacto
          </MenuListItem>
        </MenuList>
      </MenuContainer>
    </>
  );
};

export default BurgerMenu;
