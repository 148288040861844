import styled from "styled-components";
import { softGrey } from "../colors";
import { deviceMF } from "../breakPoints";

export const FooterStyled = styled.footer`
  padding: 0 2rem;
  width: 100%;
  height: 50px;
  background-color: ${softGrey};
  display: flex;
  align-items: center;
  justify-content: center;
  @media ${deviceMF.tablet} {
    justify-content: flex-end;
  }
`;
export const FooterText = styled.span`
  font-size: 14px;
`;
