import React, { useMemo } from "react";
import {
  HeaderContainer,
  IconSelect,
  LogoImage,
  NavContainer,
  NavItem,
  NavStyled,
  SelectOption,
  SelectStyled,
} from "../../assets/styles/components/header";
import Logo from "../../assets/images/svg/logo.svg";
import Mexico from "../../assets/images/icons/mexico.png";
import USA from "../../assets/images/icons/usa.png";
import { useHistory } from "react-router";
import { useGlobalContext } from "../../providers/GlobalProvider";
// import { lenguages } from "../../helpers/lenguage";

// const lenguagesValues = [
//   {
//     value: "spanish",
//     label: (
//       <SelectOption>
//         <IconSelect src={Mexico} alt="" /> Español
//       </SelectOption>
//     ),
//   },
//   {
//     value: "english",
//     label: (
//       <SelectOption>
//         <IconSelect src={USA} alt="" /> English
//       </SelectOption>
//     ),
//   },
// ];

const Header = () => {
  let history = useHistory();
  const { selectedLenguage, lenguages, value, changeLenguageCall } =
    useGlobalContext();
  const { navbar } = selectedLenguage;
  const lenguagesMemo = useMemo(
    () =>
      lenguages.map((lenguage) => ({
        ...lenguage,
        label: (
          <SelectOption>
            <IconSelect
              src={lenguage.value === "spanish" ? Mexico : USA}
              alt=""
            />
            {lenguage.label}
          </SelectOption>
        ),
      })),
    [lenguages]
  );

  const handleChangePage = (page = "/") => {
    history.push(page);
  };

  const handleChangeLenguage = (event) => {
    changeLenguageCall(event);
  };

  return (
    <HeaderContainer>
      <NavStyled>
        <LogoImage src={Logo} alt="Logo azviu electric" height="120" />
        <NavContainer>
          <NavItem onClick={() => handleChangePage("/")}>{navbar.us}</NavItem>
          <NavItem onClick={() => handleChangePage("/servicios")}>
            {navbar.services}
          </NavItem>
          <NavItem onClick={() => handleChangePage("/clientes")}>
            {navbar.customers}
          </NavItem>
          <NavItem onClick={() => handleChangePage("/proyectos")}>
            {navbar.projects}
          </NavItem>
          <NavItem onClick={() => handleChangePage("/contacto")}>
            {navbar.contact}
          </NavItem>
        </NavContainer>
      </NavStyled>

      <SelectStyled
        value={value}
        onChange={(e) => handleChangeLenguage(e)}
        options={lenguagesMemo}
      />
    </HeaderContainer>
  );
};

export default Header;
