import React from "react";
import Title from "../../components/Title";
import SwiperCore, { Autoplay, Navigation, Pagination, A11y } from "swiper";

import { Swiper, SwiperSlide } from "swiper/react";
import {
  ArticleContainer,
  ArticleSlider,
  ArticleText,
  ArticleTitle,
  ServiceImage,
  ButtonContainer,
  PageContainer,
} from "../../assets/styles/views/services";
import Subtitle from "../../components/Subtitle";
import Button from "../../components/Button";
import ServiceImg from "../../assets/images/service.jpg";

// Import Swiper styles
import "swiper/swiper-bundle.css";
import {
  SearchContainer,
  SearchInput,
} from "../../assets/styles/views/proyects";
import { useGlobalContext } from "../../providers/GlobalProvider";

// install Swiper modules
SwiperCore.use([Autoplay, Navigation, Pagination, A11y]);

const Proyects = () => {
  const { selectedLenguage } = useGlobalContext();
  const { projects } = selectedLenguage;
  return (
    <>
      <Title text={projects.title} />
      <SearchContainer>
        <SearchInput type="text" placeholder={projects.placeholder} />
      </SearchContainer>
      <PageContainer>
        {projects.projects &&
          projects.projects.map((project) => (
            <>
              <Subtitle>{project.title}</Subtitle>
              <ArticleContainer>
                <ArticleSlider>
                  <Swiper
                    slidesPerView={1}
                    freeMode={true}
                    navigation
                    autoHeight
                    loop
                    centeredSlides
                    spaceBetween={30}
                    className="mySwiper"
                  >
                    <SwiperSlide>
                      <ServiceImage src={ServiceImg} alt="Producto" />
                    </SwiperSlide>
                    <SwiperSlide>
                      <ServiceImage src={ServiceImg} alt="Producto" />
                    </SwiperSlide>
                    <SwiperSlide>
                      <ServiceImage src={ServiceImg} alt="Producto" />
                    </SwiperSlide>
                  </Swiper>
                </ArticleSlider>
                <div>
                  <ArticleTitle>{projects.descriptionTitle}:</ArticleTitle>
                  <ArticleText>{project.description}</ArticleText>
                  <ArticleTitle>{projects.typeTitle}:</ArticleTitle>
                  <ArticleText>{project.type}</ArticleText>
                  <ArticleTitle>{projects.dateTitle}:</ArticleTitle>
                  <ArticleText>{project.date}</ArticleText>
                </div>
              </ArticleContainer>
            </>
          ))}
        <ButtonContainer>
          <Button color="primary">{projects.button}</Button>
        </ButtonContainer>
      </PageContainer>
    </>
  );
};

export default Proyects;
