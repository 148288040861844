import React from "react";
import { CustomerImageContainer } from "../../assets/styles/components/customerItem";

const CustomerItem = (props) => {
  return (
    <CustomerImageContainer>
      <img {...props} />
    </CustomerImageContainer>
  );
};

export default CustomerItem;
