import styled from "styled-components";
import HomeImage from "../../images/home.jpg";
import { darkBlue, softGrey } from "../colors";
import { deviceMF } from "../breakPoints";

export const HeroContainer = styled.div`
  background-image: ${(props) => `url(${props.background})`};
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
  @media ${deviceMF.tablet} {
    height: 800px;
  }
`;

export const AboutInfoContainer = styled.div`
  display: flex;
  padding: 0;
  justify-content: center;
  background-image: url(${HomeImage});
  height: 650px;
  background-position: center bottom;
  background-repeat: no-repeat;
  background-size: 750px;
  @media ${deviceMF.tablet} {
    height: 500px;
    background-size: 850px;
    justify-content: space-between;
    padding-left: 6rem;
    background-position: 130% 50%;
  }
`;
export const AboutText = styled.p`
  min-width: 280px;
  padding: 0 1.2rem;
  align-self: flex-start;
  font-size: 16px;
  text-align: justify;
  @media ${deviceMF.mobileL} {
    padding: 0;
    width: 500px;
    font-size: 20px;
  }
  @media ${deviceMF.tablet} {
    width: 600px;
    align-self: center;
    font-size: 22px;
  }
`;
export const InfoSection = styled.section`
  background-color: ${darkBlue};
  padding: 0rem 0 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ValuesSection = styled.section`
  padding: 4rem 0 6rem;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
  @media ${deviceMF.tablet} {
    flex-direction: row;
  }
`;
export const Value = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 1.8rem;
  @media ${deviceMF.tablet} {
    margin-bottom: 0;
  }
`;
export const ValueCircle = styled.div`
  width: 220px;
  height: 220px;
  border-radius: 50%;
  border: 10px solid ${softGrey};
  margin-bottom: 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
export const ValueTitle = styled.h4`
  margin: 0.4rem 0 0;
`;
export const ValueText = styled.p`
  width: 260px;
  text-align: center;
`;
