import React, {
  createContext,
  useContext,
  useReducer,
  useCallback,
} from "react";
import { lenguages } from "../helpers/lenguage";
import { changeLenguage, initialState, reducer } from "./duck";

const GlobalContext = createContext({
  selectedLenguage: lenguages.spanish,
  value: null,
  lenguages: [
    {
      value: "spanish",
      label: "Español",
    },
    {
      value: "english",
      label: "English",
    },
  ],
  texts: lenguages,
  changeSearchCall: () => {},
});

export const useGlobalContext = () => {
  const context = useContext(GlobalContext);
  if (!context) {
    throw new Error("You can't use useGlobalContext outside a GlobalProvider");
  }
  return context;
};

const GlobalProvider = (props) => {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialState);

  const changeLenguageCall = useCallback((lenguage) => {
    dispatch(changeLenguage(lenguage));
  }, []);

  return (
    <GlobalContext.Provider
      value={{
        selectedLenguage: state.selectedLenguage,
        lenguages: state.lenguages,
        texts: state.texts,
        value: state.value,
        changeLenguageCall,
      }}
      {...props}
    >
      {children}
    </GlobalContext.Provider>
  );
};

export default GlobalProvider;
