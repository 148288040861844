import styled from "styled-components";
import {
  brightBlue,
  darkBlue,
  darkGrey,
  mediumGrey,
  softGrey,
} from "../colors";
import { SearchInput } from "./proyects";
import { deviceMF } from "../breakPoints";

export const ContactWrapper = styled.div`
  display: flex;
  height: 100%;
  padding: 0 1.2rem 3rem;
  justify-content: center;
  flex-direction: column-reverse;
  @media ${deviceMF.mobileL} {
    padding: 0 1.8rem 4rem;
  }
  @media ${deviceMF.tablet} {
    flex-direction: row;
    padding: 0 4rem 6rem;
  }
`;
export const FormStyled = styled.form`
  display: flex;
  flex-direction: column;
  padding: 1.2rem;
  border-radius: 0.6rem;
  box-shadow: 0 10px 14px rgba(0, 0, 0, 0.2);
  margin-right: 0;
  align-items: center;
  @media ${deviceMF.tablet} {
    align-items: flex-start;
    margin-right: 2rem;
  }
`;
export const FormTitle = styled.h4`
  color: ${darkBlue};
  text-align: center;
  width: 100%;
`;
export const LabelForm = styled.label`
  font-weight: bold;
  font-size: 14px;
  margin-bottom: 0.3rem;
  margin-left: 10px;
`;
export const ContactInput = styled(SearchInput)`
  width: 260px;
  margin: 0 10px 1.1rem;
  @media ${deviceMF.mobileL} {
    width: 420px;
  }
`;
export const ContactText = styled.textarea`
  width: 280px;
  font-family: Sans-serif;
  margin: 0 10px 1.1rem;
  color: ${darkGrey};
  height: 200px;
  border-radius: 8px;
  border: 1px solid ${softGrey};
  padding: 0.6rem;
  resize: none;
  &:focus {
    outline: none;
    border: 2px solid ${brightBlue};
  }
  &::placeholder {
    color: ${mediumGrey};
  }
  @media ${deviceMF.mobileL} {
    width: 420px;
  }
`;

export const ContactInfoWrapper = styled.div`
  margin-left: 0;
  margin-bottom: 2rem;
  @media ${deviceMF.tablet} {
    margin-left: 2rem;
    margin-bottom: 0;
  }
`;
export const InfoTitlte = styled.h2`
  color: ${darkBlue};
  margin: 0 0 0.4rem 10px;
  font-size: 20px;
  @media ${deviceMF.mobileL} {
    font-size: 24px;
  }
`;
export const InfoText = styled.p`
  margin: 0 0 1.6rem 10px;
`;
export const ButtonContainer = styled.div`
  align-self: center;
`;
