import React from "react";
import {
  Bar,
  H2Styled,
  TitleContainer,
} from "../../assets/styles/components/subtitle";

const Title = ({ children, isWhite = false }) => {
  return (
    <TitleContainer>
      <H2Styled isWhite={isWhite}>{children}</H2Styled>
      <Bar isWhite={isWhite}></Bar>
    </TitleContainer>
  );
};

export default Title;
